const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
const JSON_URL = process.env.REACT_APP_JSON_URL || "";
const API_URL = process.env.REACT_APP_API_URL || "";
const ENV = process.env.REACT_APP_ENVIRONMENT;
const BASE_URL = process.env.REACT_APP_BASE_URL || "";
const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION || "";
export const TRADES_PER_EVENT = parseInt(process.env.REACT_APP_TRADES_PER_EVENT || "1");
const AMOUNT_OF_TURBO = parseInt(process.env.REACT_APP_AMOUNT_OF_TURBO || "3");
const SECRET = process.env.REACT_APP_SECRET || "false";
const APP_NAME = process.env.REACT_APP_APP_NAME || "";
const SSO_URL = process.env.REACT_APP_SSO_URL || "";
const SSO_URL_SCRIPT = process.env.REACT_APP_SSO_URL_SCRIPT || "";
const REDIRECT_URL = process.env.REACT_APP_FANTASY_LINK || "";
const LOGIN_BUTTONS_URL = process.env.REACT_APP_LOGIN_BUTTONS_URL || "";
const USER_AVATAR_URL = process.env.REACT_APP_USER_AVATAR_URL || "";
export const IS_LOCALHOST = process.env.REACT_APP_IS_LOCALHOST;
export const IS_OFF_SEASON = JSON.parse(process.env.REACT_APP_IS_OFF_SEASON || "false");

export const SHARE_MESSAGE_GLOBAL = `Join the fun by creating your own MotoGP™ team, pick your bike and challenge your friends into leagues`;
export const PREDICTOR_LINK = process.env.REACT_APP_PREDICTOR_LINK || "";

export const ACCOUNT_LINK = process.env.REACT_APP_DORNA_ACCOUNT_LINK;

export const RIDER_IMAGE_VERSION = 6;

export abstract class Constant {
	public static get LOGIN_BUTTONS_URL() {
		return LOGIN_BUTTONS_URL;
	}

	public static get SSO_URL() {
		return SSO_URL;
	}

	public static get REDIRECT_URL() {
		return `${REDIRECT_URL}login/`;
	}
	public static get REDIRECT_LOGOUT_URL() {
		return REDIRECT_URL;
	}

	public static get SSO_URL_SCRIPT() {
		return SSO_URL_SCRIPT;
	}

	public static get FLAGS_URL() {
		return IMAGES_URL + "langs/";
	}

	public static get RIDERS_URL() {
		return IMAGES_URL + "riders/";
	}

	public static get CONSTRUCTORS_URL() {
		return IMAGES_URL + "constructors/";
	}

	public static get TEAMS_URL() {
		return IMAGES_URL + "squads/";
	}

	public static get WEATHER_URL() {
		return IMAGES_URL + "weather/";
	}

	public static get PRIZES_URL() {
		return IMAGES_URL + "help_page/";
	}

	public static get USER_AVATAR_URL() {
		return USER_AVATAR_URL;
	}

	public static get API_URL() {
		return API_URL;
	}

	public static get JSON_URL() {
		return JSON_URL;
	}

	public static get ENV() {
		return ENV;
	}

	public static get BASE_URL() {
		return BASE_URL;
	}

	public static get STORAGE_VERSION() {
		return STORAGE_VERSION;
	}

	public static get TRADES_PER_EVENT() {
		return TRADES_PER_EVENT;
	}

	public static get AMOUNT_OF_TURBO() {
		return AMOUNT_OF_TURBO;
	}
	public static get SECRET() {
		return SECRET;
	}
	public static get APP_NAME() {
		return APP_NAME;
	}
}

export const start_team_tooltip = {
	key: "gold.riders.tooltip",
	defValue:
		"Gold team are riders you think will perform the best among your all team. If you make a rider a Turbo Rider he will score double points but careful you have only 3 bonus throughout the season.",
};
export const reserve_team_tooltip = {
	key: "silver.riders.tooltip",
	defValue:
		"Silver Riders is the equivalent of your b-team, these riders score only 50% of total points. If you make a rider a Turbo Rider he will score double points, meaning that you will have the equivalent of a third rider in you Gold team for one GP. ",
};
export const constructor_team_tooltip = {
	key: "team.constructor.tooltip",
	defValue: "Constructor, you receive points from their overall performances.",
};
export const squad_team_tooltip = {
	key: "team.team.tooltip",
	defValue: "Team, you receive points from their overall performances.",
};
