import {FC, Fragment, PropsWithChildren, useEffect, useMemo, useState} from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {useLocation} from "react-router-dom";
import {Constant} from "modules/utils";
import {Preloader} from "components";

const languageDetector = new LanguageDetector();

export const HocI18n: FC<PropsWithChildren<unknown>> = ({children}) => {
	const [initialized, setInitialized] = useState(false);
	const {search} = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);
	const [isShowAllKeys] = useState(searchParams.get("cimode") === "true");

	useEffect(() => {
		void i18n
			.use(initReactI18next)
			.use(Backend)
			.use(languageDetector)
			.init(
				{
					/**
					 * i18nextBrowserLanguageDetector
					 */
					returnNull: false,
					detection: {
						order: [
							"languageDetectorByUrl",
							"cookie",
							"localStorage",
							"sessionStorage",
							"navigator",
						],
					},
					supportedLngs: ["en", "es", "it", "fr", "de", "id", "ja"],
					backend: {
						crossDomain: true,
						loadPath: () => {
							return `${Constant.JSON_URL}loco/{{lng}}.json`;
						},
					},
					fallbackLng: "en",
					debug: false,
					keySeparator: ".",
					interpolation: {
						escapeValue: false,
						formatSeparator: ",",
					},
				},
				() => {
					setInitialized(true);

					if (isShowAllKeys && Constant.ENV !== "production") {
						setTimeout(() => void i18n.changeLanguage("cimode"), 1000);
					}
				}
			);
	}, [isShowAllKeys]);

	return initialized ? <Fragment>{children}</Fragment> : <Preloader />;
};
